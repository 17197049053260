import { useToast, Flex, FormControl, Input } from '@chakra-ui/react';
import {
  Contact,
  ContactAddToType,
  contactsApi,
  CreateContactRequest,
} from '@texas/api/endpoints/contactsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function CreateContact({
  onCreated,
  referenceId,
  addTo,
  input,
}: {
  onCreated: (c: Contact) => void;
  referenceId: number;
  addTo: ContactAddToType;
  input: string;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const { request: createRequest, error } = useApiRequest(contactsApi.create);

  const defaultValues: CreateContactRequest = {
    referenceId,
    name: '',
    email: '',
    addTo,
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateContactRequest>({ defaultValues });

  const onSubmit = async (data: CreateContactRequest) =>
    await request(
      createRequest,
      [data],
      (c) => {
        reset(defaultValues);
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        onCreated(c);
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  useEffect(() => {
    const isEmail = input.includes('@');
    setValue('name', !isEmail ? input : '');
    setValue('email', isEmail ? input : '');
  }, [input, setValue]);

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Flex direction="column" gap={4}>
          <FormControl isRequired={true} isInvalid={!!errors.name}>
            <TexasFormLabel>{t('general.name')}</TexasFormLabel>
            <Input
              variant="texas"
              {...register('name', {
                required: true,
              })}
              placeholder={t('general.name')}
            />
            <ErrorLabel text={errors.name?.message} />
          </FormControl>
          <FormControl isRequired={true} isInvalid={!!errors.email}>
            <TexasFormLabel>{t('general.email')}</TexasFormLabel>
            <Input
              type="email"
              variant="texas"
              {...register('email', {
                required: true,
              })}
              placeholder={t('general.email')}
            />
            <ErrorLabel text={errors.email?.message} />
          </FormControl>
          <SubmitButton loading={isSubmitting}>
            {t('general.create')}
          </SubmitButton>
        </Flex>
      </form>
    </>
  );
}
