import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import {
  filesApi,
  Folder,
  UpdateFolderRequest,
  UpdateFolderResponse,
} from '@texas/api/endpoints/filesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface UpdateFolderProps extends SharedDisclosureProps {
  folder: Folder;
  onUpdated: (f: UpdateFolderResponse) => void;
}
export function EditFolderModal({
  folder,
  onUpdated,
  onClose,
  isOpen,
}: UpdateFolderProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const { request: updateRequest, error } = useApiRequest(
    filesApi.updateFolder,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateFolderRequest>();

  useEffect(() => {
    reset({
      name: folder.name,
    });
  }, [reset, folder]);

  const onSubmit = async (data: UpdateFolderRequest) =>
    await request(
      updateRequest,
      [folder.id, data],
      (f) => {
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
        onUpdated(f);
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {t('fileBrowser.editFolder')}
        </ModalHeader>
        <ModalBody>
          {error && <ErrorDetails error={error} />}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" gap={4} pb={2}>
              <FormControl isRequired={true}>
                <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                <Input
                  {...register('name', {
                    required: true,
                  })}
                  variant="outline"
                  placeholder={t('general.name')}
                  autoFocus={true}
                />
                <ErrorLabel text={errors.name?.message} />
              </FormControl>
              <SubmitButton loading={isSubmitting}>
                {t('general.update')}
              </SubmitButton>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
