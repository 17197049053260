class ArticlesTabController {
  constructor(
    $q,
    $filter,
    $stateParams,
    $state,
    loadSpinnerService,
    ConceptsApiService,
    ArticleDialogService,
    $location,
    concept,
    customer,
  ) {
    'ngInject';

    this.$q = $q;
    this.$filter = $filter;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.loadSpinnerService = loadSpinnerService;
    this.ConceptsApiService = ConceptsApiService;
    this.ArticleDialogService = ArticleDialogService;
    this.$location = $location;
    this.concept = concept;
    this.customer = customer;
  }

  newArticle(isCopy) {
    const options = {};
    options.isCopy = isCopy;
    if (this.concept) {
      options.customerId = this.concept.customerId;
      options.conceptId = this.concept.id;
    }
    if (this.customer) {
      options.customerId = this.customer.id;
    }
    this.ArticleDialogService.showDialog(options).then((article) =>
      this.$location.path(`app/articles/${article.id}`),
    );
  }
}

angular
  .module('tabs.articles', [])
  .controller('ArticlesTabController', ArticlesTabController);
