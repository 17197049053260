import { reactEvents } from '../../../../bridge/reactEvents';

// @ngInject
const relationsResolve = (article, ResourcesService) =>
  ResourcesService.loadRelations('article', article, [
    'matrixNodeX',
    'matrixNodeY',
    'concept',
    'productGroup',
    'contact',
  ]);

// @ngInject
const usersResolve = (currentOrganization, UsersApiService) =>
  UsersApiService.getAllByOrganizationId(currentOrganization.id);

// @ngInject
const conceptsResolve = (article, ConceptsApiService) =>
  ConceptsApiService.getAllByCustomerId(article.customerId);

// @ngInject
const contactsResolve = (article, ContactsApiService) =>
  ContactsApiService.getAllByCustomerId(article.customerId);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('article.management', {
    url: '/management',
    templateUrl: 'main/article/management/management.tpl.html',
    controller: 'ArticleManagementController as vm',
    resolve: {
      articleRelations: relationsResolve,
      users: usersResolve,
      concepts: conceptsResolve,
      contacts: contactsResolve,
    },
  });
};

class ArticleManagementController {
  constructor(
    $scope,
    $location,
    $filter,
    article,
    HelperService,
    AuthService,
    ArticleVariantDialogService,
    ArticlesApiService,
    loadSpinnerService,
    toasterService,
    ArticleDialogService,
    currentUser,
    ConceptsApiService,
    CopyArticleDialogService,
    concepts,
    productGroups,
    ProductGroupsApiService,
    users,
    SavingService,
    variantOptions,
    contacts,
    ContactsApiService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$filter = $filter;
    this.article = article;
    this.HelperService = HelperService;
    this.AuthService = AuthService;
    this.CopyArticleDialogService = CopyArticleDialogService;
    this.ArticleVariantDialogService = ArticleVariantDialogService;
    this.ArticlesApiService = ArticlesApiService;
    this.loadSpinnerService = loadSpinnerService;
    this.toasterService = toasterService;
    this.ArticleDialogService = ArticleDialogService;
    this.currentUser = currentUser;
    this.ConceptsApiService = ConceptsApiService;
    this.ProductGroupsApiService = ProductGroupsApiService;
    this.ContactsApiService = ContactsApiService;
    this.SavingService = SavingService;
    this.$location = $location;

    this.originalArticle = article;
    this.variantOptions = variantOptions;
    this.users = users;
    this.concepts = concepts;
    this.productGroups = productGroups;
    this.contacts = contacts;
    this.selectedConcept = null;
    this.selectedProductGroup = null;
    this.selectedOwner = null;
    this.selectedContact = null;
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'SystemAdministrator',
      'InternalAdministrator',
      'InternalAgent',
    ]);
    this.currentUserCanEditBrandOwner = AuthService.hasAnyClaim([
      'SystemAdministrator',
    ]);
    this.saveObj = SavingService.registerSave({
      onSave: () => this.save(this.getChangedData()),
      onDiscard: () => this.discard(),
      onValidate: () => this.validate(),
    });

    this.eventUnsub = reactEvents.newContactAddedToBrand.sub(() => {
      this.loadContacts();
    });

    this.setArticleClone();
    this.setWatchers();
    this.resetForm();
  }

  loadContacts() {
    this.loadSpinnerService.start('mainSpinner');
    this.ContactsApiService.getAllByCustomerId(this.article.customerId)
      .then((response) => (this.contacts = response))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  resetForm() {
    this.selectedConcept = this.article.conceptId ? this.article.concept : null;
    this.selectedProductGroup = this.article.productGroupId
      ? this.article.productGroup
      : null;
    this.selectedContact = this.article.contactId ? this.article.contact : null;
    if (this.articleForm) {
      this.articleForm.$setPristine(true);
    }
  }

  setArticleClone() {
    this.article = _.extend({}, this.originalArticle);
    this.article.owner = _.extend({}, this.originalArticle.owner);
    this.article.concept = _.extend({}, this.originalArticle.concept);
    this.article.productGroup = _.extend({}, this.originalArticle.productGroup);
    this.article.contact = _.extend({}, this.originalArticle.contact);
  }

  newVariant() {
    this.ArticleVariantDialogService.showDialog({ articleId: this.article.id });
  }

  copyArticle() {
    this.CopyArticleDialogService.showDialog({
      customerId: this.article.customerId,
      articleId: this.article.id,
      itemCodeMinLength: this.article.itemCodeMinLength,
      itemCodeMaxLength: this.article.itemCodeMaxLength,
      conceptId: this.article.conceptId ? this.article.conceptId : null,
      productGroupId: this.article.productGroupId
        ? this.article.productGroupId
        : null,
      contactId: this.article.contactId ? this.article.contactId : null,
    }).then((newArticle) =>
      this.$location.path(`app/articles/${newArticle.id}`),
    );
  }

  getChangedData() {
    const fields = [
      'name',
      'itemCode',
      'season',
      'state',
      'ownerId',
      'conceptId',
      'productGroupId',
      'contactId',
    ];

    const changedData = this.HelperService.getChangedData(
      this.originalArticle,
      this.article,
      fields,
    );

    return changedData;
  }

  save(data) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updateArticle(data, this.article.id)
      .then(
        () => {
          this.setArticleClone();
          this.toasterService.success();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  validate() {
    if (!this.articleForm.$valid) {
      this.validationErrors = [{ message: 'Form is not valid' }];
      return false;
    }
  }

  archive() {
    this.save({ archived: !this.article.archived });
  }

  discard() {
    this.validationErrors = [];
    this.setArticleClone();
    this.resetForm();
  }

  setOwner(owner) {
    if (this.currentUserCanEditBrandOwner) {
      this.article.ownerId = owner ? owner.id : 0;
      this.article.owner = owner;
    }
  }

  setConcept(concept) {
    this.article.conceptId = concept ? concept.id : 0;
    this.article.concept = concept;
  }

  getCurrentConcepts() {
    const concepts = _.filter(this.concepts, { archived: false });
    if (
      this.originalArticle.concept &&
      !_.some(concepts, { id: this.originalArticle.concept.id })
    ) {
      concepts.push(this.originalArticle.concept);
    }
    return concepts;
  }

  setProductGroup(productGroup) {
    this.article.productGroupId = productGroup ? productGroup.id : 0;
    this.article.productGroup = productGroup;
  }

  getCurrentProductGroups() {
    const productGroups = _.filter(this.productGroups, { archived: false });
    if (
      this.originalArticle.productGroup &&
      !_.some(productGroups, { id: this.originalArticle.productGroup.id })
    ) {
      productGroups.push(this.originalArticle.productGroup);
    }
    return productGroups;
  }

  setContact(contact) {
    this.article.contactId = contact ? contact.id : 0;
    this.article.contact = contact;
  }

  getCurrentContacts() {
    const contacts = _.filter(this.contacts, { archived: false });
    if (
      this.originalArticle.contact &&
      !_.some(contacts, { id: this.originalArticle.contact.id })
    ) {
      contacts.push(this.originalArticle.contact);
    }
    return contacts;
  }

  filterProductGroups(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentProductGroups(), { value: query }),
      ['value'],
    );
  }

  filterUsers(query) {
    if (this.currentUserCanEditBrandOwner) {
      return this.$filter('filter')(this.users, { uniqueName: query });
    }
  }

  filterConcepts(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentConcepts(), { name: query }),
      ['name'],
    );
  }

  filterContacts(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentContacts(), { name: query }),
      ['name'],
    );
  }

  hasMatrixNodes() {
    return (
      !!_.get(this.article, 'matrixNodeX.matrixNodeValues') &&
      !!_.get(this.article, 'matrixNodeY.matrixNodeValues')
    );
  }

  hasBranches() {
    return !!_.get(this.currentUser, 'organization.branches');
  }

  configure() {
    if (!this.hasMatrixNodes() && !this.hasBranches()) {
      return;
    }

    this.ArticleDialogService.showDialog({
      article: this.originalArticle,
    }).then((article) => {
      if (
        this.variantOptions.selectedBranch !== null &&
        !_.find(
          article.branches,
          (b) => b.id === this.variantOptions.selectedBranch.id,
        )
      ) {
        this.variantOptions.selectedBranch = null;
      }
    });
  }

  setWatchers() {
    this.$scope.$watch(
      () => {
        return !_.isEmpty(this.getChangedData());
      },
      (hasChanges) => {
        if (!hasChanges) {
          this.articleForm.$setPristine();
        }
        this.saveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.saveObj);
      this.eventUnsub();
    });
  }
}

angular
  .module('main.article.management', [])
  .config(config)
  .controller('ArticleManagementController', ArticleManagementController);
