import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Spacer,
  keyframes,
  Portal,
  MenuDivider,
  Tooltip,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetComponentSettings, WidgetType } from '../types';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { EditWidget } from './EditWidget';
import { WidgetFactoryContext } from '../../useWidgetLayout';
import { useActiveContext } from '@texas/hooks/useActiveContext';

export type WidgetStyle = 'default' | 'borderless';

interface WidgetWrapperProps {
  children: React.ReactNode;
  toolbox?: React.ReactNode;
  extendedMenu?: React.ReactNode;
  style: WidgetStyle;
  header: string;
  customColor: string | null;
  type: WidgetType;
  settings?: WidgetComponentSettings;
  loading: boolean;
  widgetId: string;
  onClick?: () => void;
}

const loadAnimation = () => {
  const animKeyframes = keyframes`
  0% { width: 0% }
  50% { width: 80%; opacity: 1 }
  100% { width: 100%; opacity: 0 }`;
  return `${animKeyframes} 1.5s linear`;
};

export function WidgetWrapper({
  children,
  style = 'default',
  widgetId,
  toolbox,
  extendedMenu,
  header,
  customColor,
  type,
  settings,
  loading,
  onClick,
}: WidgetWrapperProps) {
  const { removeWidget, updateWidget } = useActiveContext(WidgetFactoryContext);

  const widgetStyle = GetWidgetStyle(style);
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onClose: onEditClose,
    onOpen: onEditOpen,
  } = useDisclosure();

  const readOnly =
    settings?.editWidget === 'hidden' && settings.editFilters === 'hidden';

  return (
    <>
      <Flex
        {...(readOnly
          ? { border: '1px solid', borderColor: 'gray.300' }
          : null)}
        borderRadius={8}
        {...widgetStyle.widget}
        minHeight={0}
        height="100%"
        flexDir="column"
      >
        <Flex
          className="header"
          {...widgetStyle.header}
          alignItems="center"
          p={2}
          gap={2}
          {...(customColor ? { bg: `${customColor} !important` } : null)}
          {...(settings?.notDraggable
            ? null
            : { cursor: 'grab', _active: { cursor: 'grabbing' } })}
        >
          {readOnly && (
            <Tooltip label={t('widgets.readOnly')}>
              <Icons.Lock boxSize={defaultIconSize} />
            </Tooltip>
          )}
          <Heading fontSize="md">{header}</Heading>
          <Spacer />
          {toolbox}
          {!settings?.settingDots && (
            <Menu>
              <MenuButton
                as={IconButton}
                size="xs"
                variant="ghost"
                aria-label="Widget settings"
                icon={<Icons.DotsHorizontal boxSize="5" />}
              />
              <Portal>
                <MenuList>
                  {!settings?.editWidget && (
                    <>
                      <MenuItem
                        onClick={onEditOpen}
                        icon={<Icons.Pencil boxSize={5} />}
                      >
                        {t('widgets.edit')}
                      </MenuItem>
                      <MenuItem
                        onClick={onOpen}
                        icon={<Icons.Trash boxSize={5} />}
                      >
                        {t('widgets.remove')}
                      </MenuItem>
                    </>
                  )}
                  {extendedMenu && settings?.editWidget !== 'hidden' && (
                    <>
                      <MenuDivider />
                    </>
                  )}
                  {extendedMenu}
                </MenuList>
              </Portal>
            </Menu>
          )}
        </Flex>
        <Box
          animation={loadAnimation()}
          bg="texas.sand.100"
          _light={{ bg: 'texas.burntSienna.500' }}
          w="full"
          h={1}
          sx={{
            animationFillMode: 'forwards',
            animationDuration: loading ? '10s' : '1s',
          }}
        />
        <Tooltip
          label={onClick ? t('widgets.showResult') : ''}
          placement="bottom-start"
        >
          <Box
            width="100%"
            minH={0}
            padding={2}
            minW={0}
            overflow="auto"
            cursor={onClick ? 'pointer' : 'auto'}
            onClick={onClick}
            transition="opacity 200ms ease"
            {...(onClick ? { _hover: { opacity: 0.7 } } : null)}
          >
            {children}
          </Box>
        </Tooltip>
      </Flex>

      <EditWidget
        name={header}
        type={type}
        color={customColor}
        onUpdate={(name, color) => updateWidget(widgetId, name, color)}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />
      <VerifyDialogWithFunction
        headerTitle={t('general.remove')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={() => removeWidget(widgetId)}
        isOpen={isOpen}
        onClose={onClose}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithFunction>
    </>
  );
}

function GetWidgetStyle(style: WidgetStyle): {
  widget: BoxProps;
  header: BoxProps;
} {
  switch (style) {
    default:
    case 'default': {
      return {
        widget: {
          backgroundColor: 'texas.bg.900',
          _light: { backgroundColor: 'white' },
        },
        header: {
          backgroundColor: 'texas.bg.800',
          borderRadius: '8',
          borderBottomRadius: '0',
          _light: { backgroundColor: 'gray.100' },
        },
      };
    }
    case 'borderless': {
      return {
        widget: {
          border: '1px solid',
          borderColor: 'texas.bg.800',
          _light: {
            borderColor: 'gray.100',
          },
        },
        header: {
          border: '1px solid',
          borderRadius: '6',
          borderBottomRadius: 0,
          borderColor: 'texas.bg.800',
          backgroundColor: 'texas.bg.800',
          _light: {
            borderColor: 'gray.100',
            backgroundColor: 'gray.100',
          },
        },
      };
    }
  }
}
