// @ngInject
const ContactsApiService = (DS, $q, ResourcesService) => ({
  getAllByCustomerId(customerId) {
    const deferred = $q.defer();

    DS.findAll('contact', null, {
      bypassCache: true,
      endpoint: `contacts/customer/${customerId}`,
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getById(contactId) {
    const deferred = $q.defer();

    DS.find('contact', contactId, {
      bypassCache: true,
      endpoint: `contacts/${contactId}`,
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
});

angular
  .module('resources.contacts', [])
  .factory('ContactsApiService', ContactsApiService);
