import { reactEvents } from '@bridge/reactEvents';
import { Button, useDisclosure, useToast } from '@chakra-ui/react';
import {
  ConnectContactRequest,
  ContactAddToType,
  contactsApi,
} from '@texas/api/endpoints/contactsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ContactsModal } from '@texas/components/contacts/ContactsModal';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useTranslation } from 'react-i18next';

export function AngularContactsModal({
  customerId,
  customerName,
}: {
  customerId: number;
  customerName?: string;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const { t } = useTranslation();

  const { request: connectRequest } = useApiRequest(
    contactsApi.connectCustomer,
  );
  const onAdd = async (data: ConnectContactRequest) =>
    await request(
      connectRequest,
      [data],
      () => {
        toast({
          title: t('general.added'),
          status: 'success',
          isClosable: true,
        });
        reactEvents.newContactAddedToBrand.dispatch();
      },
      (connectError: ServerError) => {
        toast({
          title: t('general.addFailed'),
          description: connectError.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      <Button onClick={onOpen} variant="texas">
        {customerName
          ? t('customer.addNewContactWithName', { name: customerName })
          : t('customer.addNewContactToBrand')}
      </Button>
      <ContactsModal
        onSelect={async (c) => {
          await onAdd({ id: c.id, referenceId: customerId });
          onClose();
        }}
        onCreatedAndConnected={() => {
          onClose();
          reactEvents.newContactAddedToBrand.dispatch();
        }}
        mode="link"
        filterOn={null}
        addTo={ContactAddToType.Customer}
        referenceId={customerId}
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  );
}
