import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import {
  Contact,
  contactsApi,
  UpdateContactRequest,
} from '@texas/api/endpoints/contactsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface UpdateContactProps extends SharedDisclosureProps {
  contactId: number;
  onUpdated: (c: Contact) => void;
}
export function EditContactModal({
  contactId,
  onUpdated,
  onClose,
  isOpen,
}: UpdateContactProps) {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">{t('contacts.editContact')}</ModalHeader>
        <ModalBody>
          <Form contactId={contactId} onClose={onClose} onUpdated={onUpdated} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function Form({
  contactId,
  onClose,
  onUpdated,
}: {
  contactId: number;
  onClose: () => void;
  onUpdated: (c: Contact) => void;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    data: contact,
    refetch,
    loading,
    error,
  } = useApiResource(contactsApi.get);

  const { request: updateRequest, error: updateError } = useApiRequest(
    contactsApi.update,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateContactRequest>();

  useEffect(() => {
    refetch(contactId);
  }, [refetch, contactId]);

  useEffect(() => {
    reset({
      name: contact?.name,
      email: contact?.email,
    });
  }, [reset, contact]);

  const onSubmit = async (data: UpdateContactRequest) =>
    await request(updateRequest, [contactId, data], (f) => {
      toast({
        title: t('general.updated'),
        status: 'success',
        isClosable: true,
      });
      onUpdated(f);
      onClose();
    });

  return (
    <>
      {loading && <Spinner />}
      {error && <ErrorDetails error={error} />}
      {updateError && <ErrorDetails error={updateError} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap={4} pb={2}>
          <FormControl isRequired={true} isInvalid={!!errors.name}>
            <TexasFormLabel>{t('general.name')}</TexasFormLabel>
            <Input
              {...register('name', {
                required: true,
              })}
              variant="texas"
              placeholder={t('general.name')}
              autoFocus={true}
            />
            <ErrorLabel text={errors.name?.message} />
          </FormControl>
          <FormControl isRequired={true} isInvalid={!!errors.email}>
            <TexasFormLabel>{t('general.email')}</TexasFormLabel>
            <Input
              type="email"
              variant="texas"
              {...register('email', {
                required: true,
              })}
              placeholder={t('general.email')}
            />
            <ErrorLabel text={errors.email?.message} />
          </FormControl>
          <SubmitButton loading={isSubmitting}>
            {t('general.update')}
          </SubmitButton>
        </Flex>
      </form>
    </>
  );
}
