// @ngInject
const ResourceInit = (DS) => {
  // Converts the supplied instaces as resources
  const convertRelationObjects = (resourceName, instances) => {
    const relationDef = DS.definitions[resourceName];
    const relationsToFix = _.filter(relationDef.relationList, {
      convertRelation: true,
    });
    const instanceObjects = _.isArray(instances) ? instances : [instances];
    _.forEach(relationsToFix, (relation) => {
      _.forEach(instanceObjects, (instance) => {
        const localKeys = instance[relation.localKeys];
        instance[relation.localKeys] = _.map(localKeys, (localKey) => {
          if (relation.inject) {
            const cachedObject = DS.get(relation.relation, localKey.id);
            return cachedObject
              ? cachedObject
              : DS.inject(relation.relation, localKey);
          } else {
            return DS.createInstance(relation.relation, localKey);
          }
        });
      });
    });
  };

  const getByKeys = (Resource, relationDef, instance) =>
    DS.getAll(
      relationDef.relation,
      _.map(instance[relationDef.localKeys], 'id'),
    );

  const getRelationObjects = (Resource, relationDef, instance) =>
    relationDef.inject
      ? getByKeys(Resource, relationDef, instance)
      : instance[relationDef.localKeys];

  DS.defineResource({
    name: 'organization',
    endpoint: 'organizations',
    relations: {
      hasMany: {
        user: {
          localField: 'users',
          foreignKey: 'organizationId',
        },
        customer: {
          localField: 'customers',
          foreignKey: 'organizationId',
        },
        supplier: {
          localField: 'suppliers',
          foreignKey: 'organizationId',
        },
        branch: {
          localField: 'branches',
          foreignKey: 'organizationId',
        },
        deliveryMethod: {
          localField: 'deliveryMethods',
          foreignKey: 'organizationId',
        },
        deliveryTerm: {
          localField: 'deliveryTerms',
          foreignKey: 'organizationId',
        },
        paymentTerm: {
          localField: 'paymentTerms',
          foreignKey: 'organizationId',
        },
        handlingFee: {
          localField: 'handlingFees',
          foreignKey: 'organizationId',
        },
        material: {
          localField: 'materials',
          foreignKey: 'organizationId',
        },
        productGroup: {
          localField: 'productGroups',
          foreignKey: 'organizationId',
        },
        technique: {
          localField: 'techniques',
          foreignKey: 'organizationId',
        },
        freightCost: {
          localField: 'freightCosts',
          foreignKey: 'organizationId',
        },
        articleTemplate: {
          localField: 'articleTemplates',
          foreignKey: 'organizationId',
        },
        matrixNode: {
          localField: 'matrixNodes',
          foreignKey: 'organizationId',
        },
        technicalOption: {
          localField: 'technicalOptions',
          foreignKey: 'organizationId',
        },
        matrixGroup: {
          localField: 'matrixGroups',
          foreignKey: 'organizationId',
        },
        articleTags: {
          localField: 'articleTags',
          foreignKey: 'organizationId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'user',
    endpoint: 'users',
    computed: {
      uniqueName: ['name', 'email', (name, email) => `${name} <${email}>`],
    },
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
        branch: {
          localKey: 'branchId',
          localField: 'branch',
        },
        country: {
          localKey: 'countryId',
          localField: 'country',
        },
      },
    },
  });

  DS.defineResource({
    name: 'supplier',
    endpoint: 'suppliers',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
      hasMany: {
        supplierBranchData: {
          localField: '_branchData',
          localKeys: 'branchData',
          get: getRelationObjects,
          convertRelation: true,
        },
      },
    },
    afterInject: (options, instances) =>
      convertRelationObjects('supplier', instances),
  });

  DS.defineResource({
    name: 'supplierBranchData',
    relations: {
      hasOne: {
        deliveryMethod: {
          localField: 'deliveryMethod',
          localKey: 'deliveryMethodId',
        },
        deliveryTerm: {
          localField: 'deliveryTerm',
          localKey: 'deliveryTermId',
        },
        paymentTerm: {
          localField: 'paymentTerm',
          localKey: 'paymentTermId',
        },
        country: {
          localField: 'country',
          localKey: 'countryId',
        },
        currency: {
          localField: 'currency',
          localKey: 'currencyId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'customer',
    endpoint: 'customers',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
      hasMany: {
        concept: {
          localField: 'concepts',
          foreignKey: 'customerId',
        },
        article: {
          localField: 'articles',
          foreignKey: 'customerId',
        },
        user: {
          localField: '_users',
          localKeys: 'users',
          get: getByKeys,
        },
        customerBranchData: {
          localField: '_branchData',
          localKeys: 'branchData',
          get: getRelationObjects,
          convertRelation: true,
        },
      },
    },
  });

  DS.defineResource({
    name: 'concept',
    endpoint: 'concepts',
    relations: {
      belongsTo: {
        customer: {
          parent: true,
          localKey: 'customerId',
          localField: 'customer',
        },
      },
      hasMany: {
        article: {
          localField: 'articles',
          foreignKey: 'conceptId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'contact',
    endpoint: 'contacts',
  });

  DS.defineResource({
    name: 'article',
    endpoint: 'articles',
    relations: {
      belongsTo: {
        customer: {
          parent: true,
          localField: 'customer',
          localKey: 'customerId',
        },
        concept: {
          localField: 'concept',
          localKey: 'conceptId',
        },
        productGroup: {
          localField: 'productGroup',
          localKey: 'productGroupId',
        },
        user: {
          localField: 'owner',
          localKey: 'ownerId',
        },
        contact: {
          localField: 'contact',
          localKey: 'contactId',
        },
        matrixNode: [
          {
            localField: 'matrixNodeX',
            localKey: 'matrixNodeXId',
          },
          {
            localField: 'matrixNodeY',
            localKey: 'matrixNodeYId',
          },
        ],
      },
      hasMany: {
        variant: {
          localField: 'variants',
          foreignKey: 'articleId',
        },
        branch: {
          localField: '_branches',
          localKeys: 'branches',
          get: getByKeys,
        },
      },
    },
  });

  DS.defineResource({
    name: 'articleTemplate',
    endpoint: 'articletemplates',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
        matrixNode: [
          {
            localField: 'matrixNodeX',
            localKey: 'matrixNodeXId',
          },
          {
            localField: 'matrixNodeY',
            localKey: 'matrixNodeYId',
          },
        ],
      },
    },
  });

  DS.defineResource({
    name: 'customerBranchData',
    relations: {
      hasOne: {
        deliveryTerm: {
          localField: 'deliveryTerm',
          localKey: 'deliveryTermId',
        },
        paymentTerm: {
          localField: 'paymentTerm',
          localKey: 'paymentTermId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'articleTag',
    endpoint: 'articletags',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
    },
  });

  DS.defineResource({
    name: 'matrixNode',
    endpoint: 'matrixnodes',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
    },
  });

  DS.defineResource({
    name: 'matrixNodeValue',
    endpoint: 'matrixnodevalues',
  });

  DS.defineResource({
    name: 'variant',
    endpoint: 'variants',
    relations: {
      belongsTo: {
        article: {
          parent: true,
          localKey: 'articleId',
          localField: 'article',
        },
      },
      hasMany: {
        variantTechnicalField: {
          localField: 'variantTechnicalFields',
          foreignKey: 'variantId',
        },
        tierPrice: {
          localField: 'tierPrices',
          foreignKey: 'articleVariantId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'variantTechnicalField',
    endpoint: 'technical',
    relations: {
      belongsTo: {
        variant: {
          parent: true,
          localKey: 'variantId',
          localField: 'variant',
        },
      },
    },
  });

  DS.defineResource({
    name: 'variantPurchaseInfo',
    relations: {
      hasOne: {
        currency: {
          localField: 'currency',
          localKey: 'currencyId',
        },
        supplier: {
          localField: 'supplier',
          localKey: 'supplierId',
        },
        handlingFee: {
          localField: 'handlingFee',
          localKey: 'handlingFeeId',
        },
        freightCost: {
          localField: 'freightCost',
          localKey: 'freightCostId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'variantSalesInfo',
    endpoint: 'salesinformations',
    relations: {
      hasMany: {
        salesCurrency: {
          localField: '_currencies',
          localKeys: 'currencies',
          convertRelation: true,
          get: getRelationObjects,
        },
      },
      hasOne: {
        vatCode: {
          localField: 'vatCode',
          localKey: 'vatCodeId',
        },
      },
    },
    afterInject: (options, instances) =>
      convertRelationObjects('salesCurrency', instances),
  });

  DS.defineResource({
    name: 'salesCurrency',
    endpoint: 'salescurrencies',
    relations: {
      belongsTo: {
        variantSalesInfo: {
          parent: true,
          localField: 'salesInformation',
          localKey: 'salesInformationId',
        },
        currency: {
          localField: 'currency',
          localKey: 'currencyId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'salesPrice',
    endpoint: 'salesprices',
    relations: {
      belongsTo: {
        tierPrice: {
          parent: true,
          localField: 'tierPrice',
          localKey: 'tierPriceId',
        },
        salesCurrency: {
          localField: 'salesCurrency',
          localKey: 'salesCurrencyId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'folder',
    endpoint: 'folders',
    relations: {
      hasMany: {
        file: {
          localField: 'files',
          foreignKey: 'folderId',
        },
        folder: {
          localField: 'folders',
          foreignKey: 'parentId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'file',
    idAttribute: 'identifier',
    endpoint: 'files',
  });

  DS.defineResource({
    name: 'branch',
    endpoint: 'branches',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
      hasOne: {
        currency: {
          localField: 'defaultCurrency',
          localKey: 'defaultCurrencyId',
        },
        vatCode: {
          localField: 'defaultVatCode',
          localKey: 'defaultVatCodeId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'paymentTerm',
    endpoint: 'paymentterms',
    relations: {
      belongsTo: {
        branch: {
          parent: true,
          localKey: 'branchId',
          localField: 'branch',
        },
      },
    },
  });

  DS.defineResource({
    name: 'deliveryMethod',
    endpoint: 'deliverymethods',
    relations: {
      belongsTo: {
        branch: {
          parent: true,
          localKey: 'branchId',
          localField: 'branch',
        },
      },
    },
  });

  DS.defineResource({
    name: 'deliveryTerm',
    endpoint: 'deliveryterms',
    relations: {
      belongsTo: {
        branch: {
          parent: true,
          localKey: 'branchId',
          localField: 'branch',
        },
      },
    },
  });

  DS.defineResource({
    name: 'handlingFee',
    endpoint: 'handlingfees',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
    },
  });

  DS.defineResource({
    name: 'material',
    endpoint: 'materials',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
    },
  });

  DS.defineResource({
    name: 'productGroup',
    endpoint: 'metadata/productgroups',
    relations: {
      belongsTo: {
        organization: {
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
    },
  });

  DS.defineResource({
    name: 'technique',
    endpoint: 'techniques',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
    },
  });

  DS.defineResource({
    name: 'freightCost',
    endpoint: 'freightcosts',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
    },
  });

  DS.defineResource({
    name: 'vatCode',
    endpoint: 'vatcodes',
    relations: {
      belongsTo: {
        branch: {
          parent: true,
          localKey: 'branchId',
          localField: 'branch',
        },
      },
    },
  });

  DS.defineResource({
    name: 'tierPrice',
    endpoint: 'tierprices',
    relations: {
      belongsTo: {
        variant: {
          parent: true,
          localKey: 'articleVariantId',
          localField: 'variant',
        },
      },
      hasMany: {
        salesPrice: {
          localField: '_salesPrices',
          localKeys: 'salesPrices',
          convertRelation: true,
          get: getRelationObjects,
        },
      },
    },
  });

  DS.defineResource({
    name: 'technicalOption',
    endpoint: 'technical',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
      },
    },
  });

  DS.defineResource({
    name: 'task',
    endpoint: 'tasks',
    relations: {
      hasMany: {
        taskUser: {
          localField: '_taskUsers',
          localKeys: 'assignedUsers',
          convertRelation: true,
          get: getRelationObjects,
        },
      },
      hasOne: {
        user: {
          localField: 'creator',
          localKey: 'creatorId',
        },
      },
    },
    afterInject: (options, instances) =>
      convertRelationObjects('task', instances),
  });

  DS.defineResource({
    name: 'taskUser',
    relations: {
      hasOne: {
        user: {
          localField: 'user',
          localKey: 'userId',
        },
      },
    },
  });

  DS.defineResource({
    name: 'country',
    endpoint: 'metadata/countries',
    relations: {
      belongsTo: {
        branch: {
          localKey: 'branchId',
          localField: 'branch',
        },
      },
    },
  });

  DS.defineResource({
    name: 'currency',
    endpoint: 'currencies',
    relations: {
      belongsTo: {
        branch: {
          parent: true,
          localKey: 'branchId',
          localField: 'branch',
        },
      },
    },
  });

  DS.defineResource({
    name: 'matrixGroup',
    endpoint: 'matrixgroups',
    relations: {
      belongsTo: {
        organization: {
          parent: true,
          localKey: 'organizationId',
          localField: 'organization',
        },
        matrixNode: {
          localField: 'matrixNode',
          localKey: 'matrixNodeId',
        },
      },
    },
  });
};

angular.module('init.angularData', []).run(ResourceInit);
